import React, { useEffect, useReducer, useState } from 'react';
import { EditMaquina } from './actions/EditMaquina';
import { AddMaquina } from './actions/AddMaquina';
import { deleteMaquina } from '../../actions/maquina.action';

import { fetchSinToken } from '../../helpers/fetch';

import { MaquinasReducer } from '../../reducers/MaquinasReducer';
import { types } from '../../types/types';

import SectionTitle from '../_ui/SectionTitle/SectionTitle';
import { MaquinaCard } from '../_ui/MaquinaCard/MaquinaCard';

import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';

export const MaquinasScreen = () => {
	const [show, setShow] = useState(false);
	const [modalId, setModalId] = useState();
	const [maquinaSelected, setmaquinaSelected] = useState();

	const getID = async (id) => {
		await fetchSinToken(`maquinaria/${id}`)
			.then((data) => data.json())
			.then((data) => setmaquinaSelected(data.data))
			.then(() => handleModal(true, 'edit'))
			.catch((err) => new Error(err));
	};

	const [fetchingMaquinaria, setFetchingMaquinaria] = useState(true);
	const [maquinasData, dispatchMaquinasData] = useReducer(MaquinasReducer);

	useEffect(() => {
		if (fetchingMaquinaria) {
			fetchSinToken('maquinaria')
				.then((data) => data.json())
				.then((data) => {
					const prueba = data.data.sort((a, b) => {
						if (a.order > b.order) {
							return 1;
						}
						if (a.order < b.order) {
							return -1;
						}
						return 0;
					});
					dispatchMaquinasData({ type: types.getMaquinas, payload: prueba });
				})
				.then(() => setFetchingMaquinaria(false))
				.catch((err) => new Error(err));
		}
	}, [fetchingMaquinaria, maquinaSelected]);

	const handleDeleteMaquina = async (id) => {
		await deleteMaquina(id);
		setFetchingMaquinaria(true);
	};
	const askIfDelete = (id) => {
		Swal.fire({
			title: '¿Seguro?',
			text: 'Si borras esto, se irá al infierno de las máquinas',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: '¡Bórrala, señora!',
			cancelButtonText: '¡Uy, no!',
		}).then((result) => {
			if (result.isConfirmed) {
				handleDeleteMaquina(id);
				Swal.fire('¡Máquina borrada!', 'Esta máquina se marchó para no volver', 'success');
			}
		});
	};
	const handleModal = async (visible, modalId) => {
		setShow(visible);
		setModalId(modalId);
	};

	const displayModal = (modalId) => {
		if (show) {
			switch (modalId) {
				case 'add':
					return (
						<>
							<Modal.Header closeButton>
								<h4>Añadir Máquina</h4>
							</Modal.Header>
							<Modal.Body>
								<AddMaquina setShow={ setShow } setFetchingMaquinaria={ setFetchingMaquinaria } setmaquinaSelected={ setmaquinaSelected } />
							</Modal.Body>
						</>
					);
				case 'edit':
					return (
						<>
							<Modal.Header closeButton>
								<h4>Editando Máquina</h4>
							</Modal.Header>
							<Modal.Body>
								<EditMaquina setShow={ setShow } maquina={ maquinaSelected } setFetchingMaquinaria={ setFetchingMaquinaria } setmaquinaSelected={ setmaquinaSelected } />
							</Modal.Body>
						</>
					);
				default:
					break;
			}
		}
	};

	return (
		<>
			<SectionTitle
				image='https://res.cloudinary.com/mbr-app/image/upload/v1633957631/web/undraw_Artificial_intelligence_re_enpp_yeprz4.svg'
				title="Parque de Maquinaria"
				instructions="Aquí puedes editar y añadir tus máquinas :)"
			/>
			<button className='my-btn mini' onClick={ () => handleModal(true, 'add') }>
				Añadir Maquina
			</button>


			{ maquinasData?.length > 0 ? (
				<section className='all-maquinas'>
					{ maquinasData?.map((maq, idx) => (
						<MaquinaCard idx={ idx } key={ maq._id } maquina={ maq } getID={ getID } askIfDelete={ askIfDelete } />
					)) }
				</section>
			) : (
				<article className='empty-posts'>No tienes ninguna máquina creada</article>
			) }

			<Modal size='xl' centered show={ show } onHide={ () => handleModal(false, '') }>
				{ displayModal(modalId) }

			</Modal>
		</>
	);
};
