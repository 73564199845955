import React from 'react';
import ActionButton from '../ActionButton/ActionButton';

export const MaquinaCard = ({ maquina, idx, getID, askIfDelete }) => {
	return (
		<article className='maquina-card'>
			<div className="title-btn">
				<h6>{ maquina.name }</h6>
				<ActionButton type={ 'edit' } action={ () => getID(maquina._id) } />
				<ActionButton type={ 'delete' } action={ () => askIfDelete(maquina._id, idx) } />
			</div>
			{ maquina.order ? <p>Orden en la web: { maquina.order }</p> : <p>Orden en la web: sin definir</p> }
			<div className='maquina-card-body'>
				<figure>
					<img src={ maquina.image } alt={ maquina.name } />
				</figure>
			</div>
		</article>
	);
};
